<template>
    <div class="con-info">
        <div class="ns-info">
        <SyaratAda/>
        </div>
    </div>
  </template>
  <script>
  import SyaratAda from '@/components/Syarat.vue'
  export default {
    components: {
      // Popup,
      SyaratAda,
    },
    data() {
      return {
        
      };
    },
    computed: {
      
    },
    mounted() {
      
    },
  };
  </script>
  <style>
  </style>
  <style scoped>
  .con-info{
    background: #ffff91;
    padding: 40px;
    margin: 0px;
  }
  .ns-info{
    margin-top: 1.5rem;
    background: #fff53e;
    border-radius: 25px;
    overflow: scroll;
    box-shadow: inset 0 0 10px #ff5000;
    height: 80vh;
    padding: 20px;
  }
  </style>
  